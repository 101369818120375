import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-theme"
export default class extends Controller {
  static targets = ['toggleDarkIcon', 'toggleLightIcon'];

  connect() {
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      this.toggleLightIconTarget.classList.remove('hidden');
    } else {
      this.toggleDarkIconTarget.classList.remove('hidden');
    }
  }

  toggleTheme() {
    this.toggleDarkIconTarget.classList.toggle('hidden');
    this.toggleLightIconTarget.classList.toggle('hidden');

    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      }
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      }
    }
  }
}
