import {getToken} from "./tokenHelper";

export async function getEvent(startStr, endStr) {
  const url = `/api/events?start=${startStr}&end=${endStr}`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getToken(),
    },
  })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`)
      }
      return await response.json()
    }).catch(error => {
      console.error('API call error:', error)
      throw error;
    })
}