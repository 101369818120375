import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { getEvent } from "../api/eventHelper";

export default class extends Controller {
  static targets = ['calendar']
  static calendar;
  connect() {
    this.calendar = new Calendar(this.calendarTarget, {
      locale: 'localFr',
      height: 550,
      plugins: [interactionPlugin, dayGridPlugin],
      initialView: 'dayGridWeek',
      datesSet: this.handleDatesSet.bind(this),
      events: []
    });
    this.calendar.render()
  }

  async handleDatesSet(info) {
    const data = await getEvent(info.startStr, info.endStr)
    this.calendar.removeAllEvents()
    data.events.forEach(event  => {
      this.calendar.addEvent(event)
    })
    this.calendar.render()
  }
}
