import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { setSessionVariable, setPeriodVariable } from '../api/sessionHelper'

export default class extends Controller {
  static targets = [ "establishment", "period" ]

  selectEstablishment(event) {
    const establishment = event.currentTarget.dataset.establishment
    setSessionVariable(establishment)
  }

  selectPeriod(event) {
    const period = event.currentTarget.value
    setPeriodVariable(period)
  }
}
