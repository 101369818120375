import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "input", "passwordLevel", "case", "numbercase", "specialcase", "length" ]

  connect() {
  }

  checkPasswordStrength() {
    const password = this.inputTarget.value
    let count = 0
    count += this.toggle(this.lengthTarget, (password.length > 8))
    count += this.toggle(this.caseTarget, ((password.match(/[a-z]/) !== null) && (password.match(/[A-Z]/) !== null)))
    count += this.toggle(this.numbercaseTarget, (password.match(/[0-9]/) !== null))
    count += this.toggle(this.specialcaseTarget, (password.match(/[!@#$%^&*(),.?":{}|<>]/) !== null))

    this.showCount(count)
  }

  toggle(input, valid){
    if (valid){
      input.classList.remove("text-red-500")
      input.classList.add("text-green-500")
      input.firstElementChild.classList.remove("fa-xmark")
      input.firstElementChild.classList.add("fa-check")
      return 1
    } else {
      input.classList.add("text-red-500")
      input.classList.remove("text-green-500")
      input.firstElementChild.classList.add("fa-xmark")
      input.firstElementChild.classList.remove("fa-check")
      return 0
    }
  }

  showCount(count) {
    this.passwordLevelTargets.slice(0, count).forEach((level) => {
      level.classList.remove("bg-gray-300", "dark:bg-gray-400")
      level.classList.add("bg-green-500")
    })
    this.passwordLevelTargets.slice(count).forEach((level) => {
      level.classList.remove("bg-green-500")
      level.classList.add("bg-gray-300", "dark:bg-gray-400")
    })
  }
}