import { Controller } from "@hotwired/stimulus";
import { initFlowbite } from 'flowbite'

export default class extends Controller {
  static targets = ["list"];

  connect() {
    initFlowbite()
  }
}
