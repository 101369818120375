import {getToken} from "./tokenHelper";

export async function postComment(comment, report) {
  const url = '/api/report_comments'
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({ report_comment: {content: comment, report_id: report }})
  }).then(async response => {
    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`)
    }
    return await response.json()
  }).catch(error => {
    console.error(error)
  })
}