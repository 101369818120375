import { Controller } from "@hotwired/stimulus"
import { getAvailableUsers, addUser, removeUser } from "../api/roomHelper";

const spinner = `
  <div
  class="text-blue-800 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
  role="status" id="spinner">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>`;

export default class extends Controller {
  static targets = ["availableUser", "assignedUser", "search", "list"];

  static values = {
    id: Number,
    type: String,
    page: { type: Number, default: 0 }
  }

  static fetching = false;

  connect() {
    this.listTarget.addEventListener("scroll", this.scroll);
  }

  scroll = () => {
    if (this.#pageEnd && !this.fetching) {
      this.fetching = true;
      this.listTarget.insertAdjacentHTML("beforeend", spinner);

      this.nextPage();
    }
  }

  async nextPage() {
    this.pageValue += 1;
    try {
      const data = await getAvailableUsers(this.idValue, this.searchTarget.value, this.typeValue, this.pageValue)
      this.listTarget.querySelector("#spinner").remove();
      this.listTarget.insertAdjacentHTML("beforeend", data.html);
      if (data.count > 0) {
        this.fetching = false;
      }
    } catch (error) {
      console.error('API call error', error)
    }
  }

  get #pageEnd() {
    const { scrollHeight, scrollTop, clientHeight } = this.listTarget;
    return scrollHeight - scrollTop - clientHeight < 400;
  }

  async search(event){
    event.preventDefault()
    try {
      const data = await getAvailableUsers(this.idValue, this.searchTarget.value, this.typeValue, this.pageValue)
      this.availableUserTarget.querySelector('ul').innerHTML = data.html
    } catch (error) {
      console.error('API call error', error)
    }
  }

  async add(event){
    const userId = event.currentTarget.dataset.id
    const eventTarget = event.currentTarget
    try {
      const data = await addUser(this.idValue, userId, this.typeValue)
      const list = this.assignedUserTarget.querySelector('ul')
      list.insertAdjacentHTML("afterbegin", data.html)
      eventTarget.closest('li').remove()
    } catch (error) {
      console.error('API call error', error)
    }
  }

  async remove(event){
    const userId = event.currentTarget.dataset.id
    const eventTarget = event.currentTarget
    try {
      const data = await removeUser(this.idValue, userId, this.typeValue)
      const list = this.availableUserTarget.querySelector('ul')
      list.insertAdjacentHTML("beforeend", data.html)
      eventTarget.closest('li').remove()
    } catch (error) {
      console.error('API call error', error)
    }
  }
}
