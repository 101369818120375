import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invitation-form"
export default class extends Controller {
    static targets = [ "roleSelect", "roomWrapper", "roomSelect" ]
  connect() {
      // check if role is teacher or student for editing invitation
      if (this.roleSelectTarget.value === 'teacher' || this.roleSelectTarget.value === 'student') {
          this.roomWrapperTarget.classList.remove('hidden')
      }
  }

  handleRoleChange() {
    if (this.roleSelectTarget.value === 'teacher' || this.roleSelectTarget.value === 'student') {
      this.roomWrapperTarget.classList.remove('hidden')
    } else {
      this.roomSelectTarget.value = ''

      this.roomWrapperTarget.classList.add('hidden')
    }
  }
}
