import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["form", "search", "select"];

  connect() {
  }

  submitForm() {
    this.formTarget.submit();
  }

  resetFilters() {
    const url = new URL(window.location.href);
    const search = url.searchParams.get("search");

    if (search) {
      window.location.href = `${window.location.origin}${window.location.pathname}?search=${search}`;
    } else {
      window.location.href = `${window.location.origin}${window.location.pathname}`;
    }
  }
}
