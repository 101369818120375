import { Application } from "@hotwired/stimulus"
import Rails from 'rails-ujs';
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start();
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

const application = Application.start()
// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
