import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["test"]
  connect() {
    self.targets.forEach((target) => {
      target.innerHTML = "He"
    })
  }
}
