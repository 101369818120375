import { Controller } from "@hotwired/stimulus"
import { getFormValidation } from '../api/formValidationHelper'
export default class extends Controller {

    static targets = ["form", "output"];
    static values = { url: String };

    connect() {
        let submit = document.getElementById('submit')
        submit.disabled = true;
    }
    async keyUp(event) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(async() => {
        await this.change(event)
      }, 500)
    }

    async change(event) {
        let formData = new FormData(this.formTarget);
        formData.delete('_method')
        formData.append('_method', 'post');
        try {
            const data = await getFormValidation(this.urlValue, formData)
            this.checkInputs(data)
        } catch (error) {
            console.error("API call error:", error)
        }
    }
    checkInputs(data) {
        let errors = data['errors'];
        let labels = document.querySelectorAll('label');
        labels.forEach((label) => {
            const match = label.htmlFor.match(/[^_]+_(.*)/);
            let input = document.getElementById(label.htmlFor);
            const existingErrors = document.querySelector('#error_' + input.id);

            if (match && match[1] && errors[match[1]] && input.value && existingErrors ) {
                existingErrors.innerHTML = "<span class='font-medium'>Oops!</span> " + errors[match[1]];
            }
            if (match && match[1] && errors[match[1]] && input.value && !existingErrors ) {
                console.log('error', match[1])
                this.showError(label, input, errors[match[1]]);
            } else if (existingErrors && match && match[1] && !errors[match[1]]) {
                existingErrors.remove();
                label.classList.add("text-gray-900", "dark:text-white");
                label.classList.remove("text-red-700", "dark:text-red-500");
                input.classList.add("bg-gray-50", "border-gray-300", "text-gray-900", "focus:ring-gray-500", "focus:border-gray-500", "dark:bg-gray-700", "dark:border-gray-600", "dark:placeholder-gray-400", "dark:text-white", "dark:focus:ring-primary-500", "dark:focus:border-primary-500");
                input.classList.remove("bg-red-50", "border-red-500", "text-red-900", "placeholder-red-700", "focus:ring-red-500", "focus:border-red-500", "dark:bg-red-100", "dark:border-red-400");
            }
        });
        let submit = document.getElementById('submit')
        submit.disabled = !data['valid'];

        if (data['valid']) {
            submit.classList.remove('bg-blue-400', 'dark:bg-blue-500', 'cursor-not-allowed')
            submit.classList.add('bg-blue-500', 'dark:bg-blue-600', 'cursor-pointer')
        } else {
            submit.classList.remove('bg-blue-500', 'dark:bg-blue-600', 'cursor-pointer')
            submit.classList.add('bg-blue-400', 'dark:bg-blue-500', 'cursor-not-allowed')
        }

    }

    showError(label, input, message) {
        label.classList.add("text-red-700", "dark:text-red-500");
        label.classList.remove("text-gray-900", "dark:text-white");
        input.classList.add("bg-red-50", "border-red-500", "text-red-900", "placeholder-red-700", "focus:ring-red-500", "focus:border-red-500", "dark:bg-red-100", "dark:border-red-400");
        input.classList.remove("bg-gray-50", "border-gray-300", "text-gray-900", "focus:ring-gray-500", "focus:border-gray-500", "dark:bg-gray-700", "dark:border-gray-600", "dark:placeholder-gray-400", "dark:text-white", "dark:focus:ring-primary-500", "dark:focus:border-primary-500");

        let parent = input.parentElement;
        let p = document.createElement('p');
        p.classList = "mt-2 text-sm text-red-600 dark:text-red-500";
        p.innerHTML = "<span class='font-medium'>Oups!</span> " + message;
        p.id = "error_" + input.id;
        parent.appendChild(p);
    }
}
