import { getToken } from '../api/tokenHelper'
export function viewAllNotification() {
  fetch(`/api/notifications/view_all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getToken(),
    },
  }).then(async response => {
    if (response.ok) {
      location.reload()
    } else {
      throw new Error(`API call failed with status: ${response.status}`)
    }
  }).catch(error => {
    console.error(error)
  })
}

export function viewNotification(notificationId) {
  fetch(`/api/notifications/${notificationId}/view`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getToken(),
    },
  }).then((response) => {
    if (response.ok) {
      location.reload();
    } else {
      throw new Error(`API call failed with status: ${response.status}`)
    }
  }).catch(error => {
    console.error(error)
  });
}