import {Controller} from "@hotwired/stimulus";
import { postComment } from "../api/reportCommentHelper";

export default class extends Controller {
  static targets = ["count", "textarea", "comments"]
  static values = {
    report: String
  }

  connect() {
  }

  async send(event) {
    event.preventDefault()
    const data = await postComment(this.textareaTarget.value, this.reportValue)
    console.log('data', data)
    this.textareaTarget.value = ''
    this.commentsTarget.insertAdjacentHTML('afterBegin', data.html)
    this.countTarget.value = data.count
  }
}