import { Controller } from '@hotwired/stimulus';
import { viewAllNotification, viewNotification } from "../api/notificationHelper";

export default class extends Controller {
  connect() {}

  view_all() {
    viewAllNotification()
  }

  view(event) {
    const id = event.currentTarget.dataset.id;
    viewNotification(id)
  }
}
