import { getToken } from './tokenHelper'

export async function setSessionVariable(establishment) {
  const url = '/api/set-session-variable'
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({ establishment: establishment })
  }).then(async response => {
    if (response.ok) {
      location.href = '/dashboard'
    } else {
      throw new Error(`API call failed with status: ${response.status}`)
    }
  }).catch(error => {
    console.error(error)
  })
}

export async function setPeriodVariable(period) {
  const url = '/api/set-period-session'
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({ period: period })
  }).then(response => {
    if (response.ok) {
      location.href = "/dashboard";
    } else {
      throw new Error(`API call failed with status: ${response.status}`)
    }
  })
}