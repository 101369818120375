import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = [];

  copyToClipboard() {
    const textToCopy = this.element.dataset.clipboardText;
    navigator.clipboard.writeText(textToCopy).then(() => {
    }).catch(error => {
      console.error('Erreur lors de la copie dans le presse-papiers :', error);
    });
  }
}
