import { getToken } from "./tokenHelper";

export async function getAvailableUsers(roomId, searchValue, typeValue, pageValue) {
  const url = `/rooms/${roomId}/available_users`
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({search: searchValue, type: typeValue, page: pageValue})
  })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`)
      }
      return await response.json()
    }).catch(error => {
      console.error('API call error:', error)
      throw error;
  })
}

export async function addUser(roomId, userId, typeValue) {
  const url = `/rooms/${roomId}/add_user`
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({user_id: userId, type: typeValue})
  })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`)
      }
      return await response.json()
    }).catch(error => {
    console.error('API call error:', error)
    throw error;
  })
}

export async function removeUser(roomId, userId, typeValue) {
  const url = `/rooms/${roomId}/remove_user`
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getToken()
    },
    body: JSON.stringify({user_id: userId, type: typeValue})
  })
    .then(async response => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`)
      }
      return await response.json()
    }).catch(error => {
    console.error('API call error:', error)
    throw error;
  })
}