import Rails from 'rails-ujs';
import {getToken} from "./tokenHelper";

export async function getFormValidation(urlValue, formData) {
  console.log('formData', formData)
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url: urlValue,
      type: "POST",
      data: formData,
      success: (data) => {
        resolve(data)
      },
      error: (error) => {
        console.error('API call error:', error)
        reject(error)
      }
    });
  })
}